<template>
  <div>
    <!-- 工程信息查询 -->
    <div class="content1">
      <div class="all">
        <div class="SearchBar">
          <!--搜索框-->
          <el-form
            ref="search"
            :inline="true"
            :model="search"
            size="small"
            label-width="115px"
          >
            <el-form-item label="工程编号/名称" class="lab">
              <el-input
                v-model="search.numberOrName"
                placeholder="请输入"
                class="SearchInput"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="现场管理员" class="lab">
              <el-input
                  v-model="search.siteManager"
                  placeholder="请输入"
                  class="SearchInput"
                  clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="第三方施工队" class="lab">
              <el-input
                  v-model="search.teamName"
                  placeholder="请输入"
                  class="SearchInput"
                  clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="工程类型" class="lab">
              <el-select
                v-model="search.projectType"
                clearable
                placeholder="请选择"
              >
                <el-option
                  v-for="item in statusList"
                  :key="item.code"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="工程状态" class="lab">
              <el-select v-model="search.status" clearable placeholder="请选择">
                <el-option
                  v-for="item in typeList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="报装工程项目" class="lab">
              <el-select v-model="search.bz" clearable placeholder="请选择">
                <el-option
                  v-for="item in dataList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item class="lab" label="创建时间">
              <el-date-picker
                v-model="searchDatatime"
                type="datetimerange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                clearable
                value-format="yyyy-MM-dd HH:mm:ss"
                :default-time="['00:00:00', '23:59:59']"
              >
              </el-date-picker>
            </el-form-item>
            <el-button
              type="primary"
              size="small"
              @click="getList(1)"
              >查询</el-button
            >
            <el-button
              type="primary"
              plain
              size="small"
              @click="reset"
              >重置</el-button
            >
            <el-button
                type="primary"
                size="small"
                @click="exportFile(1)"
            >导出选中项</el-button
            >
            <el-button
                type="primary"
                size="small"
                @click="exportFile(2)"
            >导出列表</el-button
            >
          </el-form>
        </div>
        <div class="table">
          <!--数据表格-->
          <el-table
            ref="tableData"
            :data="tableData"
            style="width: 100%; margin: 15px 0"
            border
            height="calc(100vh - 402px)"
            :stripe="true"
            :default-sort="{ prop: 'createTime', order: 'descending' }"
            :row-key="getRowKeys"
            @selection-change="handleSelectionChange">
            <el-table-column
                type="selection"
                width="55"
                :reserve-selection="true">
            </el-table-column>
            <el-table-column
              type="index"
              label="序号"
              width="70"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="projectNumber"
              label="工程编号"
              align="center"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="projectName"
              label="工程名称"
              align="center"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="siteManager"
              label="现场管理员"
              align="center"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="projectType"
              label="工程类型"
              align="center"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="contractTypeName"
              label="施工合同"
              align="center"
              show-overflow-tooltip
            >
              <template slot-scope="{ row }">
                <div @click="examine(row)">
                  <el-button  size="small">查看</el-button>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="orderCode"
              label="报装工单"
              align="center"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="realname"
              label="第三方施工队"
              align="center"
              show-overflow-tooltip
            >
              <template slot-scope="{ row }">
                {{ row.installName }} {{ row.drillingName }}
                {{ row.earthworkName }}
              </template>
            </el-table-column>
            <el-table-column
              prop="createTime"
              label="创建时间"
              align="center"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="startTime"
              label="开工时间"
              align="center"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="status"
              label="工程状态"
              align="center"
              show-overflow-tooltip
            >
              <template slot-scope="{ row }">
                <el-tag v-if="row.status" :type="row.status[row.status.length-1]=='中'?'warning':(row.status[row.status.length-1]=='成'?'success':'')">{{ row.status }}</el-tag>
              </template>
            </el-table-column>
            <el-table-column
              prop="isStop"
              label="开停工状态"
              align="center"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="operation"
              label="操作"
              align="left"
              width="200px"
            >
              <template slot-scope="{ row }">
                <el-button type="primary" size="small" @click="desc(row)"
                  >工程详情</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <div class="pageBox">
            <el-pagination
              :current-page="search.current"
              :background="true"
              :page-sizes="[10, 30, 50, 100]"
              :page-size="search.size"
              layout="total, prev, pager, next, sizes, jumper"
              :total="total"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
    <!-- 合同 -->
    <el-dialog
      :visible.sync="dialogVisibleDesc"
      width="50%"
      :close-on-click-modal="false"
      @close="clearnF('form')"
    >
      <el-form
        :inline="true"
        ref="addform1"
        :model="form"
        label-width="120px"
        size="mini"
      >
        <div>
          <div class="particulars">
            <span class="img"></span>
            <span>合同信息</span>
          </div>
          <el-row>
            <el-col :span="8">
              <el-form-item label="合同来源：" prop="positionName">
                {{ form.contractFromStr }}
              </el-form-item>
            </el-col>
            <el-col :span="8" v-if="form.contractFrom==1">
              <el-form-item label="报装单号：" prop="positionName">
                {{ form.orderCode }}
              </el-form-item>
            </el-col>
            <el-col :span="8" v-if="form.contractFrom==1">
              <el-form-item label="报装工单信息：" prop="positionName">
                <el-button type="text" @click="details(form.orderCode)">点击查看</el-button>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="甲方：" prop="positionName">
                {{ form.firstParty }}
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="联系电话：" prop="positionName">
                {{ form.phone }}
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="联系地址：" prop="positionName">
                {{ form.address }}
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="合同类型：" prop="positionName">
                {{ form.contractTypeParent }}({{form.contractTypeChild}})
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="合同编号：" prop="positionName">
                {{ form.contractNumber }}
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="合同附件:" prop="positionCode">
                <el-image
                  style="width: 100px; height: 100px"
                  :src="contractAttachment[0]"
                  :preview-src-list="contractAttachment"
                >
                </el-image>
                <span style="margin-left: 10px"
                  >共{{ contractAttachment.length }}张</span
                >
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="缴费佐证:" prop="positionCode">
                <el-image
                  style="width: 100px; height: 100px"
                  :src="payProof[0]"
                  :preview-src-list="payProof"
                >
                </el-image>
                <span style="margin-left: 10px">共{{ payProof.length }}张</span>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="签约说明">
                {{ form.signDescription }}
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="签约人">
                {{ form.signRealname }}
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="签约时间">
                {{ form.signTime }}
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-form>
     
    </el-dialog>
    <!-- 查看详情 -->
    <el-dialog
      :visible.sync="dialogVisibledetails"
      width="80%"
      :close-on-click-modal="false"
      @close="clearnFDetare('form')"
    >
      <el-form
        :inline="true"
        ref="addform1"
        :model="form"
        label-width="120px"
        size="mini"
      >
        <div>
          <div class="particulars">
            <span class="img"></span>
            <span>工程信息</span>
            <div class="state">{{ form.status }}</div>
          </div>
          <el-row>
            <el-col :span="6">
              <el-form-item label="工程编号：" prop="positionName">
                {{ form.projectNumber }}
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="工程名称：" prop="positionName">
                {{ form.projectName }}
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="工程类型：" prop="positionName">
                {{ form.projectType }}
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="工程设计：" prop="positionName">
                {{ form.isDesign == 1 ? '需要' :'不需要' }}
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
             <el-col :span="6">
              <el-form-item label="现场管理员：" prop="positionName">
                {{ form.siteManager }}
              </el-form-item>
            </el-col>
            <el-col :span="18">
              <el-form-item label="工程地点：" prop="positionName">
                {{ form.projectSite }}
              </el-form-item>
            </el-col>
            
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="验收范围：" prop="positionName">
                {{ form.acceptScope }}
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="工程说明：" prop="positionName">
                {{ form.projectExplain }}
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="4">
              <el-form-item label="创建人：" prop="positionName">
                {{ form.realname }}
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="创建时间：" prop="positionName">
                {{ form.createTime }}
              </el-form-item>
            </el-col>
          </el-row>
          <div class="particulars">
            <span class="img"></span>
            <span>第三方施工队</span>
          </div>
          <el-row v-if="form.status != '已登记'">
            <el-col :span="8" v-if="form.installName">
              <el-form-item label="安装施工队：" prop="positionName">
                {{ form.installName }}
              </el-form-item>
            </el-col>
            <el-col :span="8" v-if="form.earthworkName">
              <el-form-item label="土方施工队：" prop="positionName">
                {{ form.earthworkName }}
              </el-form-item>
            </el-col>
            <el-col :span="8" v-if="form.drillingName">
              <el-form-item label="定向钻施工队：" prop="positionName">
                {{ form.drillingName }}
              </el-form-item>
            </el-col>
          </el-row>
          <el-row v-if="form.status == '已登记'">
            <el-col :span="8">
              <el-form-item label="暂未派工"> </el-form-item>
            </el-col>
          </el-row>
          <div class="particulars" v-if="form.scatter != 1 && form.isDesign == 1">
            <span class="img"></span>
            <span>技术交底</span>
          </div>
          <el-row v-if="form.techTransitionBO && form.scatter != 1">
            <el-col :span="8">
              <el-form-item label="交底资料：" prop="positionName"><span style="color:#0F71E2" @click="dialogTransitionBO = true">点击查看<i class="el-icon-arrow-right"></i></span>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="交底确认时间：" prop="positionName">
                {{ form.techTransitionBO.confirmTime }}
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="交底确认情况：" prop="positionName">
                现场管理员
                <img
                  src="../../assets/choose.png"
                  height="16"
                  width="16"
                  v-if="form.techTransitionBO.siteManagerConfirm == 2"
                />
                <img
                  src="../../assets/nochoose.png"
                  height="16"
                  width="16"
                  v-if="form.techTransitionBO.siteManagerConfirm == 1"
                />
                安装施工队
                <img
                  src="../../assets/choose.png"
                  height="16"
                  width="16"
                  v-if="form.techTransitionBO.installTeamConfirm == 2"
                />
                <img
                  src="../../assets/nochoose.png"
                  height="16"
                  width="16"
                  v-if="form.techTransitionBO.installTeamConfirm == 1"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row v-if="form.statusCode <= 2 && form.scatter != 1">
            <el-col :span="8">
              <el-form-item label="暂未交底"> </el-form-item>
            </el-col>
          </el-row>
          <div class="particulars" v-if="form.scatter != 1">
            <span class="img"></span>
            <span>开工情况</span>
          </div>
          <el-row v-if="form.workStartTime && form.scatter != 1">
            <el-col :span="12">
              <el-form-item label="开工时间：" prop="positionName">
                {{ form.workStartTime }}
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="开停工状态：" prop="positionName">
                {{ form.returnMode}}
                <span @click="recordClick(form.id)" style="color:#0F71E2">查看停复工记录<i class="el-icon-arrow-right"></i></span>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row v-if="form.statusCode <= 3 && form.scatter != 1">
            <el-col :span="8">
              <el-form-item label="暂未开工"> </el-form-item>
            </el-col>
          </el-row>
          <div class="particulars" v-if="form.scatter != 1">
            <span class="img"></span>
            <span>最新施工进度</span>
          </div>
          <el-row v-if="form.progressBO && form.scatter != 1">
            <el-col :span="8" v-if="form.progressBO.installImageUrl">
              <el-form-item label="安装施工队:" prop="positionCode">
                <el-image
                  style="width: 100px; height: 100px"
                  :src="
                    form.progressBO.installImageUrl &&
                    form.progressBO.installImageUrl.split(',').length > 0
                      ? form.progressBO.installImageUrl.split(',')[0]
                      : ''
                  "
                  :preview-src-list="form.progressBO.installImageUrl.split(',')"
                >
                </el-image>
                <span style="margin-left: 10px"
                  >共{{
                    form.progressBO.installImageUrl.split(",") &&
                    form.progressBO.installImageUrl.split(",").length > 0
                      ? form.progressBO.installImageUrl.split(",").length
                      : 0
                  }}张</span
                >
              </el-form-item>
            </el-col>
            <el-col :span="8" v-if="form.progressBO.earthworkImageUrl">
              <el-form-item label="土方施工队:" prop="positionCode">
                <el-image
                  style="width: 100px; height: 100px"
                  :src="
                    form.progressBO.earthworkImageUrl.split(',') &&
                    form.progressBO.earthworkImageUrl.split(',').length > 0
                      ? form.progressBO.earthworkImageUrl.split(',')[0]
                      : ''
                  "
                  :preview-src-list="
                    form.progressBO.earthworkImageUrl.split(',')
                  "
                >
                </el-image>
                <span style="margin-left: 10px"
                  >共{{
                    form.progressBO.earthworkImageUrl.split(",") &&
                    form.progressBO.earthworkImageUrl.split(",").length > 0
                      ? form.progressBO.earthworkImageUrl.split(",").length
                      : 0
                  }}张</span
                >
              </el-form-item>
            </el-col>
            <el-col :span="8" v-if="form.progressBO.drillingImageUrl">
              <el-form-item label="定向钻施工队:" prop="positionCode">
                <el-image
                  style="width: 100px; height: 100px"
                  :src="
                    form.progressBO.drillingImageUrl &&
                    form.progressBO.drillingImageUrl.split(',').length > 0
                      ? form.progressBO.drillingImageUrl.split(',')
                      : ''
                  "
                  :preview-src-list="
                    form.progressBO.drillingImageUrl.split(',')
                  "
                >
                </el-image>
                <span style="margin-left: 10px"
                  >共{{
                    form.progressBO.drillingImageUrl &&
                    form.progressBO.drillingImageUrl.split(",").length > 0
                      ? form.progressBO.drillingImageUrl.split(",").length
                      : 0
                  }}张</span
                >
              </el-form-item>
            </el-col>
          </el-row>
          <el-row v-if="form.progressBO && form.scatter != 1">
            <el-col :span="8">
              <el-form-item
                label="进度说明"
                v-if="form.progressBO.installDescription"
              >
                {{ form.progressBO.installDescription }}
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item
                label="进度说明"
                v-if="form.progressBO.earthworkDescription"
              >
                {{ form.progressBO.earthworkDescription }}
              </el-form-item>
            </el-col>
            <el-col :span="8" v-if="form.progressBO.drillingDescription">
              <el-form-item label="进度说明">
                {{ form.progressBO.drillingDescription }}
              </el-form-item>
            </el-col>
          </el-row>
          <el-row v-if="form.statusCode <= 3 && form.scatter != 1">
            <el-col :span="8">
              <el-form-item label="暂未开工"> </el-form-item>
            </el-col>
          </el-row>
          <el-row v-if="form.statusCode >= 3 && !form.progressBO && form.scatter != 1">
            <el-col :span="8">
              <el-form-item label="暂无进度"> </el-form-item>
            </el-col>
          </el-row>
          <div class="particulars">
            <span class="img"></span>
            <span>验收范围</span>
          </div>
          <el-row v-if="form.statusCode <= 4">
            <el-col :span="8">
              <el-form-item label="暂未验收"> </el-form-item>
            </el-col>
          </el-row>
          <el-row v-if="form.statusCode > 4">
            <el-col :span="24">
              <el-form-item label="验收情况：" prop="positionName">
                <span v-if="form.isMediumPressure != 0">
                  看压吹扫-中压
                  <img
                    src="../../assets/choose.png"
                    height="16"
                    width="16"
                    v-if="form.isMediumPressure == 2"
                  />
                  <img
                    src="../../assets/nochoose.png"
                    height="16"
                    width="16"
                    v-if="form.isMediumPressure != 2"
                  />
                </span>
                <span v-if="form.isLowPressure != 0">
                  看压吹扫-低压
                  <img
                    src="../../assets/choose.png"
                    height="16"
                    width="16"
                    v-if="form.isLowPressure == 2"
                  />
                  <img
                    src="../../assets/nochoose.png"
                    height="16"
                    width="16"
                    v-if="form.isLowPressure != 2"
                  />
                </span>
                <span v-if="form.isRadiation != 0">
                  敷设环境
                  <img
                    src="../../assets/choose.png"
                    height="16"
                    width="16"
                    v-if="form.isRadiation == 2"
                  />
                  <img
                    src="../../assets/nochoose.png"
                    height="16"
                    width="16"
                    v-if="form.isRadiation != 2"
                  />
                </span>
                <span v-if="form.isMarker != 0">
                  地面标志物
                  <img
                    src="../../assets/choose.png"
                    height="16"
                    width="16"
                    v-if="form.isMarker == 2"
                  />
                  <img
                    src="../../assets/nochoose.png"
                    height="16"
                    width="16"
                    v-if="form.isMarker != 2"
                  />
                </span>
                <span v-if="form.isResident != 0">
                  集体户验收
                  <img
                    src="../../assets/choose.png"
                    height="16"
                    width="16"
                    v-if="form.isResident == 2"
                  />
                  <img
                    src="../../assets/nochoose.png"
                    height="16"
                    width="16"
                    v-if="form.isResident != 2"
                  />
                </span>
                <el-popconfirm
                    confirm-button-text='好的'
                    icon="el-icon-info"
                    icon-color="red"
                    title="这是一段内容确定删除吗？"
                >

                </el-popconfirm>
                <span v-if="form.isCity != 0">
                  城市集体户户内和立管
                  <img style="cursor: pointer"
                    src="../../assets/choose.png"
                    height="16"
                    width="16"
                    v-if="form.isCity == 2"
                    @click="showTime(1)"
                  />
                  <img
                    src="../../assets/nochoose.png"
                    height="16"
                    width="16"
                    v-if="form.isCity != 2"
                  />
                </span>
                <span v-if="form.isIndustry != 0">
                  工商福单户
                  <img
                    src="../../assets/choose.png"
                    height="16"
                    width="16"
                    v-if="form.isIndustry == 2"
                  />
                  <img
                    src="../../assets/nochoose.png"
                    height="16"
                    width="16"
                    v-if="form.isIndustry != 2"
                  />
                </span>
                <span v-if="form.isVillage != 0">
                  村村通
                  <img
                      style="cursor: pointer"
                    src="../../assets/choose.png"
                    height="16"
                    width="16"
                    v-if="form.isVillage == 2"
                    @click="showTime(2)"
                  />
                  <img
                    src="../../assets/nochoose.png"
                    height="16"
                    width="16"
                    v-if="form.isVillage != 2"
                  />
                </span>
              </el-form-item>
            </el-col>
          </el-row>
          <div class="particulars"  v-if="form.projectType !='零散工程施工' ">
            <span class="img"></span>
            <span>项目后期情况</span>
          </div>
          <el-row v-if="form.statusCode <= 5 && (form.projectType !='零散工程施工')">
            <el-col :span="8">
              <el-form-item label="暂无信息"> </el-form-item>
            </el-col>
          </el-row>
          <el-row  v-if="form.statusCode == 6 && form.acceptanceAndCompletionVO && (form.projectType !='零散工程施工')">
            <el-col :span="12">
              <el-form-item label="竣工资料归档：" prop="positionName">
                <span v-if="form.acceptanceAndCompletionVO.acceptance && form.acceptanceAndCompletionVO.acceptance.drilling != 0">
                  定向钻施工队
                  <img
                    src="../../assets/choose.png"
                    height="16"
                    width="16"
                    v-if="form.acceptanceAndCompletionVO.acceptance.drilling  == 1"
                  />
                  <img
                    src="../../assets/nochoose.png"
                    height="16"
                    width="16"
                    v-if="form.acceptanceAndCompletionVO.acceptance.drilling != 1"
                  />
                </span>
                <span v-if="form.acceptanceAndCompletionVO.acceptance && form.acceptanceAndCompletionVO.acceptance.install != 0">
                  安装施工队
                  <img
                    src="../../assets/choose.png"
                    height="16"
                    width="16"
                    v-if="form.acceptanceAndCompletionVO.acceptance.install == 1"
                  />
                  <img
                    src="../../assets/nochoose.png"
                    height="16"
                    width="16"
                    v-if="form.acceptanceAndCompletionVO.acceptance.install != 1"
                  />
                </span>
              </el-form-item>
            </el-col>
            <el-col :span="12" v-if="
                form.acceptanceAndCompletionVO.completion && (
                  form.acceptanceAndCompletionVO.completion.drilling ||
                  form.acceptanceAndCompletionVO.completion.install || 
                  orm.acceptanceAndCompletionVO.completion.earthwork
                )">
                <el-form-item label="验收工程量：" prop="positionName">
                <span v-if="form.acceptanceAndCompletionVO.completion && form.acceptanceAndCompletionVO.completion.drilling != 0">
                  定向钻施工队
                  <img
                    src="../../assets/choose.png"
                    height="16"
                    width="16"
                    v-if="form.acceptanceAndCompletionVO.completion.drilling  == 1"
                  />
                  <img
                    src="../../assets/nochoose.png"
                    height="16"
                    width="16"
                    v-if="form.acceptanceAndCompletionVO.completion.drilling != 1"
                  />
                </span>
                <span v-if="form.acceptanceAndCompletionVO.completion && form.acceptanceAndCompletionVO.completion.install != 0">
                  安装施工队
                  <img
                    src="../../assets/choose.png"
                    height="16"
                    width="16"
                    v-if="form.acceptanceAndCompletionVO.completion.install == 1"
                  />
                  <img
                    src="../../assets/nochoose.png"
                    height="16"
                    width="16"
                    v-if="form.acceptanceAndCompletionVO.completion.install != 1"
                  />
                </span>
                <span v-if="form.acceptanceAndCompletionVO.completion && form.acceptanceAndCompletionVO.completion.earthwork != 0">
                  土方施工队
                  <img
                    src="../../assets/choose.png"
                    height="16"
                    width="16"
                    v-if="form.acceptanceAndCompletionVO.completion.earthwork == 1"
                  />
                  <img
                    src="../../assets/nochoose.png"
                    height="16"
                    width="16"
                    v-if="form.acceptanceAndCompletionVO.completion.earthwork != 1"
                  />
                </span>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-form>
     
    </el-dialog>
    <!-- 交底资料 -->
    <el-dialog
      :visible.sync="dialogTransitionBO"
      width="20%"
      title="项目后期情况"
      :close-on-click-modal="false"
    >
      <el-form
        :inline="true"
        ref="addform1"
        :model="form"
        label-width="120px"
        size="mini"
      >
          <el-row v-if="form.techTransitionBO && form.techTransitionBO.fileUrl">
            <el-col :span="24">
              <el-form-item label="交底文件:" prop="positionCode">
                <el-image
                  style="width: 100px; height: 100px"
                  :src="form.techTransitionBO.fileUrl.split(',')[0]"
                  :preview-src-list="form.techTransitionBO.fileUrl.split(',')"
                >
                </el-image>
                <span style="margin-left: 10px">共{{ form.techTransitionBO.fileUrl.split(',').length }}张</span>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="交底说明">
                {{ form.techTransitionBO && form.techTransitionBO.transitionDescription }}
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="提交人">
                {{ form.techTransitionBO && form.techTransitionBO.transitionApplyRealname }}
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="提交时间">
                {{ form.techTransitionBO && form.techTransitionBO.transitionApplyTime }}
              </el-form-item>
            </el-col>
          </el-row>
      </el-form>
      
    </el-dialog>
    <!-- 查看停复工记录 -->
    <el-dialog
      :visible.sync="dialogRecord"
      width="50%"
      title="停复工记录"
      :close-on-click-modal="false"
    >
       <el-table
            ref="tableData"
            :data="tableRecord"
            style="width: 100%; margin: 15px 0"
            border
            class="table-search1"
            :stripe="true"
            :default-sort="{ prop: 'createTime', order: 'descending' }"
          >
            <el-table-column
              type="index"
              label="序号"
              width="70"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="registerType"
              label="操作类型"
              align="center"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="registerTime"
              label="停复工开始时间"
              align="center"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="explain"
              label="停复工描述"
              align="center"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="realname"
              label="提交人"
              align="center"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="createTime"
              label="提交时间"
              align="center"
              show-overflow-tooltip
            ></el-table-column>
          </el-table>
     
    </el-dialog>
    <!-- 工单弹窗 -->
    <el-dialog
        title="工单信息"
        :visible.sync="orderVisible"
        append-to-body
        width="20%"
      >
        <div class="info-content">
          <el-row class="info-item">
            <span class="label">工单单号：</span>
            <span class="content-item">{{ orderInfo.orderCode }}</span>
          </el-row>
          <el-row class="info-item">
            <span class="label">用户类型：</span>
            <span class="content-item">{{ orderInfo.userType }}</span>
          </el-row>
          <el-row class="info-item">
            <span class="label">用气设备：</span>
            <span class="content-item">{{ orderInfo.equipment }}</span>
          </el-row>
          <el-row class="info-item">
            <span class="label">执行位置：</span>
            <span class="content-item">{{ orderInfo.address }}</span>
          </el-row>
          <el-row class="info-item">
            <span class="label">执行简图：</span>
            <span class="content-item">
              <el-image
                style="width: 100px; height: 100px"
                :src="orderInfo.firstImageUrl"
                :preview-src-list="orderInfo.imageUrl"
              >
              </el-image>
              <span v-if="orderVisible"
                >共{{
                  orderInfo.imageUrl ? orderInfo.imageUrl.length : 0
                }}张</span
              >
            </span>
          </el-row>
          <el-row class="info-item">
            <span class="label">测量员：</span>
            <span class="content-item">{{ orderInfo.realname }}</span>
          </el-row>
          <el-row class="info-item">
            <span class="label">创建时间：</span>
            <span class="content-item">{{ orderInfo.createTime }}</span>
          </el-row>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="orderVisible = false">关 闭</el-button>
        </span>
      </el-dialog>
    <el-dialog
      :visible.sync="showTimedialog"
      width="20%"
    >
      <p style="line-height: 40px">验收完成操作时间：{{showTimeType==1?showTimeobj.cityDate:showTimeobj.villageDate}}</p>
      <p style="line-height: 40px">验收完成操作人：{{showTimeType==1?showTimeobj.cityUser:showTimeobj.villageUser}}</p>
        <span slot="footer" class="dialog-footer">
          <el-button @click="showTimedialog = false">关 闭</el-button>
        </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  projectList,
  projectInfoList,
  projectStatus,
  contractInfo,
  projectDetail,
  projectStoppages
} from "@/RequestPort/earlier/index.js";
import { getDict } from "../../apis/commonType";
import axios from "@/apis/contractApi";
import {projectInfoListExport, projectInfoListExportValidate} from "@/RequestPort/cost/cost";
import { exportMethod } from '../../common/js/exportExcel'
export default {
  name: "conntract",
  mixins: [],
  data() {
    return {
      tell: [{ name: "现场管理员" }],
      showTimeType:null,
      showTimeobj:{},
      search: { current: 1, size: 100 }, //搜索
      searchContractTypeChildIdArr: [],
      // 时间组
      searchDatatime: [],
      total: 10,
      tableData: [], //列表对象
      statusList: [], //工程类型
      typeList: [], //工程zhuangtai
      contractAttachment: [],
      payProof: [],
      // 只要没有进去验收中，可以调整！同时，进入验收中，只要没有相关的验收业务数据产生，可以删除；同时可增加新的验收
      disabled: false,
      dialogVisible: false,
      showTimedialog: false,
      // 新增编辑输入框宽度
      breadth: "width:300px",
      // 表单提交
      form: {
        payProof: "",
        contractAttachment: "",
        techTransitionBO: {},
        progressBO: {},
      },
      rule: {
        contractCode: {
          required: true,
          message: "请选择合同编号",
          trigger: "blur",
        },
        startDate: {
          required: true,
          message: "请选择签订日期",
          trigger: "blur",
        },
        endDate: { required: true, message: "请选择截至日期", trigger: "blur" },
        //  urlList:{ required: true, message: "请上传附件合同", trigger: "change" },
      },
      rulesApp: {},
      // 查看合同弹窗
      dialogVisibleDesc: false,
      // 查看详情
      dialogVisibledetails: false,
      parentName: "",

      repairBeforFileFormData: new FormData(),
      isturn: 0,
      source: 1,
      // 包装工程
      dataList: [
        { label: "是", value: "1" },
        { label: "否", value: "2" },
      ],
      teamDsc: {},
      // 验收范围
      dialogVisibleApply: false,
      applyName: "",
      applyflag: "",
      // 验收范围（确认弹窗）
      activeName: "接口返回数据",
      // 交底弹窗
      dialogTransitionBO:false,
      // 停工记录
      dialogRecord:false,
      // 停复工记录数据
      tableRecord:[],
      // 工单详情
      orderVisible:false,
      // 工单详情对象
      orderInfo:{},
      multipleSelection:[]
    };
  },
  mounted() {
    // 登记列表
    this.getList();
    getDict({ parentCode: "2100" }).then((res) => {
      this.statusList = res.data;
    });
    //查状态
    projectStatus().then((res) => {
      this.typeList = res.data;
    });
  },
  methods: {
    showTime(type){
      this.showTimeType = type
      this.showTimedialog = true
      this.showTimeobj = {
           cityUser:this.form.cityUser,
          cityDate:this.form.cityDate,
         villageUser:this.form.villageUser,
         villageDate:this.form.villageDate
      }
    },
    isAllEqual(array) {
      if (array.length > 0) {
        return !array.some(function(value, index) {
          return value !== array[0];
        });
      } else {
        return true;
      }
    },
    exportFile(type){
      let date = new Date();
      let year = date.getFullYear()+'';
      let month = date.getMonth() + 1+'';
      let day = date.getDate()+'';
      let hour= date.getHours()+'';//得到小时数
      let minute= date.getMinutes()+'';//得到分钟数
      let second= date.getSeconds()+'';//得到秒数
      let time = year+month+day+hour+minute+second

      if(type==1){//选中导出
        let ids=[]
        let types= []
        if(this.multipleSelection.length>0){
          this.multipleSelection.forEach(item=>{
            types.push(item.projectTypeId)
            ids.push(item.id)
          })
          if(this.isAllEqual(types)){
            const loading = this.$loading({
              lock: true,
              text: '正在导出，数据量较多可能时间较长，请耐心等待',
              spinner: 'el-icon-loading',
              background: 'rgba(0, 0, 0, 0.7)'
            });
            //导出
            projectInfoListExport(ids).then(res=>{
              exportMethod(res, '工程量汇总_'+time)
              loading.close();
            })
          }else {
            return this.$message.error('只支持同一工程类型的工程量导出')
          }
        }else {
          return this.$message.error('请勾选工程后，操作导出')
        }
      }else {//全部导出
        const loading = this.$loading({
          lock: true,
          text: '正在导出，数据量较多可能时间较长，请耐心等待',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        projectInfoListExportValidate(this.search).then(res=>{
          projectInfoListExport(res.data).then(res=>{
            exportMethod(res, '工程量汇总_'+time)
            loading.close();
          })
        })
      }
    },
    getRowKeys(row) {
      return row.id
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    /**
     * 打开停工记录弹窗
     */
    recordClick(row){
      
      projectStoppages({id:row}).then(res => {
        this.tableRecord = res.data
      })
      this.dialogRecord = true
    },
    /**
     * 查看合同
     */
    examine(row) {
      this.dialogVisibleDesc = true;
      contractInfo({ contractId: row.contractId }).then((res) => {
        this.form = res.data;
        if (res.data.contractAttachment) {
          this.contractAttachment = res.data.contractAttachment.split(",");
        }
        if (res.data.payProof) {
          this.payProof = res.data.payProof.split(",");
        }
      });
    },
    /**
     * 查询列表
     */
    getList(current) {
      if (this.searchDatatime) {
        this.search.startTime = this.searchDatatime[0];
        this.search.endTime = this.searchDatatime[1];
      }
      if (current) {
        this.search.current = 1;
        this.$refs.tableData.clearSelection()
      }
      //查列表
      projectInfoList(this.search).then((res) => {
        this.tableData = res.data.records;
        this.total = res.data.total;
      });
    },
    /**
     * 订单查看详情
     */
    async details(orderCode) {
      console.log("订单详情");
      // this.$router.push('');
      let that = this;
      //  查询工单信息
      try {
        let res = await axios.getOrderInfo({ orderCode });
        that.orderInfo = res.data;
        that.orderInfo.imageUrl = that.orderInfo.imageUrl.split(",");
        that.orderInfo["firstImageUrl"] =
          that.orderInfo.imageUrl && that.orderInfo.imageUrl.length > 0
            ? that.orderInfo.imageUrl[0]
            : "";
      } catch (e) {}

      this.orderVisible = true;
    },
    //重置查询条件
    reset() {
      (this.search = {
        current: 1,
        size: 100,
        dateType: "1",
        teamName:''
      }),
        (this.searchContractTypeChildIdArr = []);
      this.searchDatatime = [];
      this.getList(1);
    },

    handleSizeChange(val) {
      this.search.size = val;
      this.getList(1);
    },
    handleCurrentChange(val) {
      this.search.current = val;
      this.getList();
    },
    clearnF() {
      this.form = {};
      this.teamDsc = {};
      if (this.$refs["addform"]) {
        this.$refs["addform"].resetFields();
      }

      //  this.$refs['Applyform'].resetFields();
    },
    // 关闭详情
    clearnFDetare() {
      this.dialogVisibledetails = false;
      this.form = {};
    },
    /**
     * 查看详情
     */
    desc(row) {
      this.dialogVisibledetails = true;
      projectDetail({ id: row.id }).then((res) => {
        if (res.code == 200) {
          console.log(res.data);
          this.form = res.data;
        }
      });
    },
    /**
     * 验收范围
     */
    apply(row, flag) {
      this.form.id = row.id;
      console.log(this.form.id);
      this.dialogVisibleApply = true;
    },
  },
};
</script>

<style  lang="less" scoped>

::v-deep .el-upload--picture-card {
  width: 100px;
  height: 100px;
  line-height: 100px;
}
::v-deep .el-upload-list--picture-card .el-upload-list__item {
  width: 100px;
  height: 100px;
  line-height: 100px;
}
.searchType {
  width: 210px;
  box-shadow: 1px 1px 5px #888888;
  position: absolute;
  z-index: 100;
  background-color: #fff;
}
.SearchBar {
  /* border: 1px solid #ecedf1; */
  border-radius: 5px;
  margin: 10px;
  padding-top: 1%;
  background-color: #ffffff;
}
.SearchInput {
  width: 100%;
}
.SearchOptions {
  width: 100%;
}
.SearchBtn {
  margin-left: 20px;
}
.SearchBtn2 {
  margin-left: 10px;
}
.table {
  padding: 10px;
  padding-top: 5px;
  border-radius: 5px;
  margin: 10px;
  background-color: #ffffff;
  height: auto;
}

.all {
  background-color: #f3f4f8;
}
.content {
  background: #f3f4f8;
  width: 100%;
  border-radius: 6px;
  padding: 10px;
}
.diaBtn {
  margin-right: 2%;
}
.diaInput {
  width: 82%;
}
.dialog_form {
  margin: 30px;
  padding: 10px;
}
.red {
  color: #f74b4b;
}
.radioList {
  display: block;
  margin: 10px 0px;
}
.particulars {
  display: flex;
  flex-direction: row;

  height: 40px;
  position: relative;
  .img {
    border: 1px solid #3f74d5;
    height: 20px;
    margin-right: 2px;
  }
  .state {
    width: 60px;
    height: 30px;
    position: absolute;
    top: -10px;
    left: 80px;
    color: #f18d1b;
    line-height: 30px;
    text-align: center;
    border: 1px solid #f18d1b;
  }
}
</style>
